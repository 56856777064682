import React, { useState } from "react";
import "./style.css";
import * as ROUTE from "../Constants/routes";
import login_back from "../../assests/images/login_back.png";
import forgot_pass from "../../assests/images/forgot_pass.png";
import axios from "axios";
import LoginInterface from "../Modules/LoginModalInterface/LoginModalInterface";
import { useNavigate } from "react-router-dom";
import { setUser } from "../../store/actioncreators/actionCreators";
import { Dispatch } from "redux";
import { useDispatch } from "react-redux";
import { Spinner } from "../utils/loader";
import { IoMdClose } from "react-icons/io";
import ReactGA from "react-ga4";
import { RequestMethods } from "../../shared/RequestMethods";
import { ResponseStatusCode } from "../../shared/ResponseStatusCode";

const Signin: React.FC<LoginInterface> = ({ handleLoginModal }) => {
  const dispatch: Dispatch<any> = useDispatch();

  const [signin, setSigninData] = useState({
    email: "",
    password: "",
    signinModal: true,
  });
  const [sigin_loader, setLoader] = useState(false);
  const [forgot_msg, setForgotMSG] = useState({
    msg: "",
    show: false,
  });
  const [signin_msg, setSigninMSG] = useState({
    msg: "",
    show: false,
  });

  const showForgotMsg = () => {
    setForgotMSG({
      msg: "Please check your mail for further details",
      show: true,
    });
  };

  const showSigninMsg = (meessage: string) => {
    setSigninMSG({ msg: meessage, show: true });
  };

  const setUserDetails = React.useCallback(
    (newuser: USER) => dispatch(setUser(newuser)),
    [dispatch]
  );

  let navigate = useNavigate();

  let handleNewPassword = async () => {
    let regexpEmail = new RegExp("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$");
    if (!regexpEmail.test(signin.email)) {
      let email = document.getElementById("email") as HTMLInputElement;
      email?.classList.remove("text-slate-500");
      email?.classList.add("text-red-500");
      email.textContent = "Enter valid email";
    }

    toggleLoader();

    axios
      .get(
        `${process.env.REACT_APP_BASE_API}/users/sendforgetpasswordemail/${signin.email}`,
        {
          method: RequestMethods.GET,
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Credentials": true,
            xsrfCookieName: "XSRF-TOKEN",
            xsrfHeaderName: "X-XSRF-TOKEN",
          },
          withCredentials: true,
          data: {
            email: signin.email,
          },
        }
      )
      .finally(() => {
        toggleLoader();
        closeLoader();
        showForgotMsg();
      });
  };

  let showPass = () => {
    let passkey = document.getElementById(
      "passkey_input"
    ) as HTMLInputElement | null;
    if (passkey?.type === "text") {
      passkey.setAttribute("type", "password");
    } else {
      passkey?.setAttribute("type", "text");
    }
  };

  let handleSignUpNavi = () => {
    handleLoginModal();
    navigate("/signup");
  };

  let fetchDetails = async (extoken: string) => {
    let config = {
      method: RequestMethods.GET,
      url: `${process.env.REACT_APP_BASE_API}/users/getLoggedInUserDetails`,
      headers: {
        Authorization: `Bearer ${extoken}`,
      },
    };

    await axios(config)
      .then(function (response) {
        if (response.status === ResponseStatusCode.OK) {
          let getuser: USER = {
            ...response.data,
            id: response.data._id,
            admin: +response.data.userRole,
            token: extoken,
            loader: false,
            user: {
              name: response.data.fullName,
              email: response.data.email,
              phone: response.data.phoneNumber,
              country: response.data.countryCode,
              ref: response.data.referenceCode,
              pwd: signin.password,
              emailVerified: response.data.isEmailVerified,
              coverImg: response.data.bannerImage,
              rejected: response.data.isRejected,
              userVerified: response.data.isUserVerified,
              profileImg: response.data.profileImage,
            },
            company: response.data.company ?? [],
          };
          setUserDetails(getuser);
        } else {
          showSigninMsg("Please enter valid login details");
        }
      })
      .catch(function (error) {
        showSigninMsg("Please enter valid login details");
        closeLoader();
      });
  };

  const handleLogin = async () => {
    const regexpEmail = new RegExp("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$");
    const email = document.getElementById("email") as HTMLInputElement;
    const passkey = document.getElementById("passkey") as HTMLInputElement;

    if (!signin.email || !signin.password) {
      showSigninMsg("Please enter Email and Password");
      return;
    }

    if (!regexpEmail.test(signin.email)) {
      email?.classList.remove("text-slate-500");
      email?.classList.add("text-red-500");
      email.textContent = "Enter valid email id";
      return;
    }

    toggleLoader();

    const data = JSON.stringify({
      email: signin.email,
      password: signin.password,
    });

    const config = {
      method: RequestMethods.POST,
      url: `${process.env.REACT_APP_BASE_API}/users/login`,
      headers: {
        "Content-Type": "application/json",
      },
      data,
    };

    await axios(config)
      .then(function (response) {
        console.log({ response });
        if (response.data.status === ResponseStatusCode.OK) {
          localStorage.setItem(
            "GTI_data",
            response.data.message.access_token +
              " " +
              response.data.message.userDetails.email +
              " " +
              response.data.message.userDetails._id
          );

          ReactGA.event({
            category: "Authentication",
            action: "SignIn",
            label: "",
          });

          fetchDetails(response.data.message.access_token);
          handleLoginModal();
          // navigate(ROUTE.HOME);
          window.location.reload();
        } else {
          showSigninMsg(response?.data?.message);
        }
        closeLoader();
      })
      .catch(function (error) {
        console.log({ error });
        email?.classList.remove("text-slate-500");
        email?.classList.add("text-red-500");
        email.textContent = "Enter valid email id";
        passkey?.classList.remove("text-slate-500");
        passkey?.classList.add("text-red-500");
        passkey.textContent = "Enter valid password";
        toggleLoader();
      });
  };

  const toggleLoader = () => {
    setLoader(!sigin_loader);
  };
  const closeLoader = () => {
    setLoader(false);
  };

  return (
    <div className="container">
      {signin.signinModal ? (
        <div className="main">
          <div className="side-left">
            <img
              className="hidden md:flex w-3/5 h-3/5"
              src={login_back}
              alt="Sign In"
            />
          </div>
          <div className="side-right">
            <div className="header w-full">
              <ul className="header-content">
                <li className="signin">Sign in</li>
              </ul>
              <span
                onClick={() => {
                  handleLoginModal();
                }}
                className="cancle font-bold cursor-pointer"
              >
                <IoMdClose />
              </span>
            </div>
            <div>
              <div className="relative group">
                <input
                  type="text"
                  id="email_input"
                  onChange={(e) => {
                    setSigninData({ ...signin, email: e.target.value });
                  }}
                  className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                  placeholder=" "
                />
                <label
                  id="email"
                  className="absolute text-sm text-gray-500 peer-focus:text-blue-600  duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white  px-2 peer-focus:px-2  peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1"
                >
                  Email
                </label>
              </div>
              <div className="group relative">
                <input
                  type="password"
                  id="passkey_input"
                  onChange={(e) => {
                    setSigninData({ ...signin, password: e.target.value });
                  }}
                  className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                  placeholder=" "
                />
                <label
                  id="passkey"
                  className="absolute text-sm text-gray-500 peer-focus:text-blue-600  duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white  px-2 peer-focus:px-2  peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1"
                >
                  Password
                </label>
              </div>

              <div className="group flex justify-between ">
                <div className="inline-flex items-center">
                  <span className="p-1">
                    <input
                      type="checkbox"
                      onChange={() => {
                        showPass();
                      }}
                      className="content-checkbox cursor-pointer"
                    />
                  </span>
                  <p className="content-extra">Show Password</p>
                </div>
                <button
                  onClick={() => {
                    setSigninData({ ...signin, signinModal: false });
                    setForgotMSG({ msg: "", show: false });
                    setSigninMSG({ msg: "", show: false });
                    closeLoader();
                  }}
                  className="content-extra"
                >
                  Forgot Password ?
                </button>
              </div>
              <div className="flex flex-col items-center justify-center">
                <button
                  className="content-button"
                  onClick={() => {
                    handleLogin();
                  }}
                >
                  Submit
                  {sigin_loader && <Spinner />}
                </button>
                <div className="content-block">
                  <p className="content-extra">
                    Don't have an account?
                    <span className="text-GTI-BLUE-default mx-1">
                      <button
                        onClick={() => {
                          handleSignUpNavi();
                        }}
                      >
                        Sign up
                      </button>
                    </span>
                  </p>
                </div>
                {signin_msg.show && (
                  <p className="text-red-500 text-sm font-bold">
                    {signin_msg.msg}
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="main">
          <div className="side-left">
            <img
              className="hidden md:flex duration-500 p-5 ease-in-out"
              src={forgot_pass}
              alt="Forgot Password"
            />
          </div>
          <div className="side-right">
            <div className="header w-full ">
              <ul className="header-content">
                <li className="signin">Forgot Password</li>
              </ul>
              <span
                onClick={() => {
                  handleLoginModal();
                }}
                className="cancle font-bold"
              >
                X
              </span>
            </div>
            <div className="my-5">
              <div className="group relative">
                <input
                  type="text"
                  id="forgot_email_input"
                  onChange={(e) => {
                    setSigninData({ ...signin, email: e.target.value });
                  }}
                  className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                  placeholder=" "
                />
                <label
                  id="forgot_email"
                  className="absolute text-sm text-gray-500 peer-focus:text-blue-600  duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white  px-2 peer-focus:px-2  peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1"
                >
                  Enter your Email id
                </label>
              </div>
              <div className="flex flex-col items-center justify-center my-5">
                <button
                  className="content-button"
                  onClick={() => {
                    handleNewPassword();
                  }}
                >
                  Get new password{sigin_loader && <Spinner />}
                </button>
                <div className="content-block">
                  <p className="content-extra">
                    Back to
                    <span className="text-GTI-BLUE-default font-bold mx-1">
                      <button
                        onClick={() => {
                          setSigninData({ ...signin, signinModal: true });
                          setForgotMSG({ msg: "", show: false });
                          closeLoader();
                        }}
                      >
                        Sign in
                      </button>
                    </span>
                  </p>
                </div>
                {forgot_msg.show && (
                  <p className="text-gray-400 text-sm ">{forgot_msg.msg}</p>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Signin;
