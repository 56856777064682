import axios from "axios";
import {
  APPROVED,
  oppotunityItem,
  oppotunityUpdateItem,
  REJECTED,
  STORAGE_KEY,
} from "../../Components/constants";
import { store } from "../../store";
import { GET_OPPORTUNITY } from "../actiontypes/actionTypes";
import { spinnerLoaderStart, spinnerLoaderStop } from "./loaderactions";

export const getOpportunities =
  (
    skip: string,
    limit: string,
    secId?: string,
    subSecId?: string,
    search?: string
  ) =>
  (dispatch: any) => {
    dispatch(spinnerLoaderStart());

    let url = `${process.env.REACT_APP_BASE_API}/opportunities?skip=${skip}&limit=${limit}&search=${search}`;

    if (secId) url = `${url}&sectorId=${secId}`;
    if (subSecId) url = `${url}&subSectorId=${subSecId}`;

    var configUnsigned = {
      method: "get",
      url,
    };
    var configSigned = {
      method: "get",
      url: `${process.env.REACT_APP_BASE_API}/opportunities/loggedinusers?skip=${skip}&limit=${limit}`,
      headers: {
        Authorization: `Bearer ${store.getState().USER.USER.token}`,
        "Content-Type": "application/json",
      },
    };

    axios(configUnsigned)
      .then(function (response) {
        const list: OPP = {
          TOTAL: response.data.opportunitiesCount,
          OPP_LIST: response.data,
          YOUR_OPP_LIST: store.getState().OPP.OPP.YOUR_OPP_LIST,
          COMPANY_OPP_LIST: store.getState().OPP.OPP.COMPANY_OPP_LIST,
          COMPANY_PENDING_OPP_LIST:
            store.getState().OPP.OPP.COMPANY_PENDING_OPP_LIST,
          COMPANY_APPROVED_OPP_LIST:
            store.getState().OPP.OPP.COMPANY_APPROVED_OPP_LIST,
          COMPANY_UNAPPROVED_OPP_LIST:
            store.getState().OPP.OPP.COMPANY_UNAPPROVED_OPP_LIST,
          COMPANY_REJECTED_OPP_LIST:
            store.getState().OPP.OPP.COMPANY_REJECTED_OPP_LIST,
          TOTAL_PENDING: store.getState().OPP.OPP.TOTAL_PENDING,
          TOTAL_APPROVED: store.getState().OPP.OPP.TOTAL_APPROVED,
          TOTAL_UNAPPROVED: store.getState().OPP.OPP.TOTAL_UNAPPROVED,
          TOTAL_REJECTED: store.getState().OPP.OPP.TOTAL_REJECTED,
        };
        const action: GET_OPP_ACTION = {
          type: GET_OPPORTUNITY,
          OPP: list,
        };
        dispatch(action);
        dispatch(spinnerLoaderStop());
      })
      .catch(function (error) {
        dispatch(spinnerLoaderStop());
      });
  };
export const getYourOpportunities =
  (skip: string, limit: string, option: string) => (dispatch: any) => {
    const new_skip = (+skip * +limit).toString();
    dispatch(spinnerLoaderStart());

    const token: string = localStorage.getItem("GTI_data")?.split(" ")[0] ?? "";

    var url = "";
    switch (option) {
      case APPROVED:
        url = `${process.env.REACT_APP_BASE_API}/opportunities/user?opportunityStatus=APPROVED`;
        break;
      case REJECTED:
        url = `${process.env.REACT_APP_BASE_API}/opportunities/user?opportunityStatus=REJECTED`;
        break;
      default:
        url = `${process.env.REACT_APP_BASE_API}/opportunities/user?opportunityStatus=PENDING`;
    }
    var config = {
      method: "get",
      url: url,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    };

    axios(config)
      .then(function (response) {
        const list: OPP = {
          TOTAL: response.data?.opportunitiesCount,
          OPP_LIST: store.getState().OPP.OPP.OPP_LIST,
          YOUR_OPP_LIST: response.data.opportunities,
          COMPANY_OPP_LIST: store.getState().OPP.OPP.COMPANY_OPP_LIST,
          COMPANY_PENDING_OPP_LIST:
            store.getState().OPP.OPP.COMPANY_PENDING_OPP_LIST,
          COMPANY_APPROVED_OPP_LIST:
            store.getState().OPP.OPP.COMPANY_APPROVED_OPP_LIST,
          COMPANY_UNAPPROVED_OPP_LIST:
            store.getState().OPP.OPP.COMPANY_UNAPPROVED_OPP_LIST,
          COMPANY_REJECTED_OPP_LIST:
            store.getState().OPP.OPP.COMPANY_REJECTED_OPP_LIST,
          TOTAL_PENDING: store.getState().OPP.OPP.TOTAL_PENDING,
          TOTAL_APPROVED: store.getState().OPP.OPP.TOTAL_APPROVED,
          TOTAL_UNAPPROVED: store.getState().OPP.OPP.TOTAL_UNAPPROVED,
          TOTAL_REJECTED: store.getState().OPP.OPP.TOTAL_REJECTED,
        };
        const action: GET_OPP_ACTION = {
          type: GET_OPPORTUNITY,
          OPP: list,
        };
        dispatch(action);
        dispatch(spinnerLoaderStop());
      })
      .catch(function (error) {
        dispatch(spinnerLoaderStop());
      });
  };
export const createOppotunity =
  (item: oppotunityItem) => (dispatch: OPP_DISPATCH) => {
    var data: oppotunityItem = {
      description: item.description,
      technologyPartnerRequirement: item.technologyPartnerRequirement,
      documents: item.documents,
      images: item.images,
      sectorId: item.sectorId,
      subSectorId: item.subSectorId,
      name: item.name,
    };

    var config = {
      method: "post",
      url: `${process.env.REACT_APP_BASE_API}/opportunities`,
      headers: {
        Authorization: `Bearer ${store.getState().USER.USER.token}`,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        // console.log("OPP created");
      })
      .catch(function (error) {
        // console.log("OPP not created", error);
      });
  };
export const updateOppotunity =
  (item: oppotunityUpdateItem) => (dispatch: OPP_DISPATCH) => {
    // console.log(item);
    var data: oppotunityUpdateItem = {
      opportunityId: item.opportunityId,
      description: item.description,
      technologyPartnerRequirement: item.technologyPartnerRequirement,
      document: item.document,
      image: item.image,
      sectorId: item.sectorId,
      subSectorId: item.subSectorId,
    };
    var config = {
      method: "patch",
      url: `${process.env.REACT_APP_BASE_API}/opportunities`,
      headers: {
        Authorization: `Bearer ${store.getState().USER.USER.token}`,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        // console.log("OPP updated");
      })
      .catch(function (error) {
        // console.log("OPP not updated", error);
      });
  };

//company admin
export const getCompanyPendingOPP =
  (skip: string, limit: string) => (dispatch: OPP_DISPATCH) => {
    const new_skip = (+skip * +limit).toString();
    var config = {
      method: "get",
      url: `${process.env.REACT_APP_BASE_API}/opportunities/companypendingopportunities`,
      headers: {
        Authorization: `Bearer ${store.getState().USER.USER.token}`,
        "Content-Type": "application/json",
      },
    };

    axios(config)
      .then(function (response) {
        // console.log("fetched ");
        const list: OPP = {
          TOTAL: store.getState().OPP.OPP.TOTAL_PENDING,
          OPP_LIST: store.getState().OPP.OPP.OPP_LIST,
          YOUR_OPP_LIST: store.getState().OPP.OPP.YOUR_OPP_LIST,
          COMPANY_OPP_LIST: store.getState().OPP.OPP.COMPANY_OPP_LIST,
          COMPANY_PENDING_OPP_LIST: response.data.opportunities,
          COMPANY_APPROVED_OPP_LIST:
            store.getState().OPP.OPP.COMPANY_APPROVED_OPP_LIST,
          COMPANY_UNAPPROVED_OPP_LIST:
            store.getState().OPP.OPP.COMPANY_UNAPPROVED_OPP_LIST,
          COMPANY_REJECTED_OPP_LIST:
            store.getState().OPP.OPP.COMPANY_REJECTED_OPP_LIST,
          TOTAL_PENDING: response.data.opportunitiesCount,
          TOTAL_APPROVED: store.getState().OPP.OPP.TOTAL_APPROVED,
          TOTAL_UNAPPROVED: store.getState().OPP.OPP.TOTAL_UNAPPROVED,
          TOTAL_REJECTED: store.getState().OPP.OPP.TOTAL_REJECTED,
        };
        const action: GET_OPP_ACTION = {
          type: GET_OPPORTUNITY,
          OPP: list,
        };
        return dispatch(action);
      })
      .catch(function (error) {});
  };

export const getCompanyUnapprovedOPP =
  (skip: string, limit: string) => (dispatch: OPP_DISPATCH) => {
    const new_skip = (+skip * +limit).toString();
    var config = {
      method: "get",
      url: `${process.env.REACT_APP_BASE_API}/opportunities/companyunapprovedopportunities`,
      headers: {
        Authorization: `Bearer ${store.getState().USER.USER.token}`,
        "Content-Type": "application/json",
      },
    };

    axios(config)
      .then(function (response) {
        // console.log("fetched ");
        const list: OPP = {
          TOTAL: response.data.opportunitiesCount,
          OPP_LIST: store.getState().OPP.OPP.OPP_LIST,
          YOUR_OPP_LIST: store.getState().OPP.OPP.YOUR_OPP_LIST,
          COMPANY_OPP_LIST: store.getState().OPP.OPP.COMPANY_OPP_LIST,
          COMPANY_PENDING_OPP_LIST:
            store.getState().OPP.OPP.COMPANY_PENDING_OPP_LIST,
          COMPANY_APPROVED_OPP_LIST:
            store.getState().OPP.OPP.COMPANY_APPROVED_OPP_LIST,
          COMPANY_UNAPPROVED_OPP_LIST: response.data.opportunities,
          COMPANY_REJECTED_OPP_LIST:
            store.getState().OPP.OPP.COMPANY_REJECTED_OPP_LIST,
          TOTAL_PENDING: store.getState().OPP.OPP.TOTAL_PENDING,
          TOTAL_APPROVED: store.getState().OPP.OPP.TOTAL_APPROVED,
          TOTAL_UNAPPROVED: response.data.opportunitiesCount,
          TOTAL_REJECTED: store.getState().OPP.OPP.TOTAL_REJECTED,
        };
        const action: GET_OPP_ACTION = {
          type: GET_OPPORTUNITY,
          OPP: list,
        };
        return dispatch(action);
      })
      .catch(function (error) {});
  };

export const getCompanyApprovedOPP =
  (skip: string, limit: string) => (dispatch: OPP_DISPATCH) => {
    const new_skip = (+skip * +limit).toString();
    var config = {
      method: "get",
      url: `${process.env.REACT_APP_BASE_API}/opportunities/companyapprovedopportunities`,
      headers: {
        Authorization: `Bearer ${store.getState().USER.USER.token}`,
        "Content-Type": "application/json",
      },
    };

    axios(config)
      .then(function (response) {
        // console.log("fetched ");
        const list: OPP = {
          TOTAL: +response.data.opportunitiesCount,
          OPP_LIST: store.getState().OPP.OPP.OPP_LIST,
          YOUR_OPP_LIST: store.getState().OPP.OPP.YOUR_OPP_LIST,
          COMPANY_OPP_LIST: store.getState().OPP.OPP.COMPANY_OPP_LIST,
          COMPANY_PENDING_OPP_LIST:
            store.getState().OPP.OPP.COMPANY_PENDING_OPP_LIST,
          COMPANY_APPROVED_OPP_LIST: response.data.opportunities,
          COMPANY_UNAPPROVED_OPP_LIST:
            store.getState().OPP.OPP.COMPANY_UNAPPROVED_OPP_LIST,
          COMPANY_REJECTED_OPP_LIST:
            store.getState().OPP.OPP.COMPANY_REJECTED_OPP_LIST,
          TOTAL_PENDING: store.getState().OPP.OPP.TOTAL_APPROVED,
          TOTAL_APPROVED: response.data.opportunitiesCount,
          TOTAL_UNAPPROVED: store.getState().OPP.OPP.TOTAL_UNAPPROVED,
          TOTAL_REJECTED: store.getState().OPP.OPP.TOTAL_REJECTED,
        };
        const action: GET_OPP_ACTION = {
          type: GET_OPPORTUNITY,
          OPP: list,
        };
        return dispatch(action);
      })
      .catch(function (error) {});
  };
export const getCompanyRejectedOPP =
  (skip: string, limit: string) => (dispatch: OPP_DISPATCH) => {
    const new_skip = (+skip * +limit).toString();
    var config = {
      method: "get",
      url: `${process.env.REACT_APP_BASE_API}/opportunities/companyrejectedopportunities`,
      headers: {
        Authorization: `Bearer ${store.getState().USER.USER.token}`,
        "Content-Type": "application/json",
      },
    };

    axios(config)
      .then(function (response) {
        // console.log("fetched ");
        const list: OPP = {
          TOTAL: response.data.opportunitiesCount,
          OPP_LIST: store.getState().OPP.OPP.OPP_LIST,
          YOUR_OPP_LIST: store.getState().OPP.OPP.YOUR_OPP_LIST,
          COMPANY_OPP_LIST: store.getState().OPP.OPP.COMPANY_OPP_LIST,
          COMPANY_PENDING_OPP_LIST:
            store.getState().OPP.OPP.COMPANY_PENDING_OPP_LIST,
          COMPANY_APPROVED_OPP_LIST:
            store.getState().OPP.OPP.COMPANY_APPROVED_OPP_LIST,
          COMPANY_UNAPPROVED_OPP_LIST:
            store.getState().OPP.OPP.COMPANY_UNAPPROVED_OPP_LIST,
          COMPANY_REJECTED_OPP_LIST: response.data.opportunities,
          TOTAL_PENDING: store.getState().OPP.OPP.TOTAL_PENDING,
          TOTAL_APPROVED: store.getState().OPP.OPP.TOTAL_APPROVED,
          TOTAL_UNAPPROVED: store.getState().OPP.OPP.TOTAL_UNAPPROVED,
          TOTAL_REJECTED: +response.data.opportunitiesCount,
        };
        const action: GET_OPP_ACTION = {
          type: GET_OPPORTUNITY,
          OPP: list,
        };
        return dispatch(action);
      })
      .catch(function (error) {});
  };

export const approveOPP = (id: string) => (dispatch: OPP_DISPATCH) => {
  var config = {
    method: "patch",
    url: `${process.env.REACT_APP_BASE_API}/opportunities/approveopportunity/${id}`,
    headers: {
      Authorization: `Bearer ${store.getState().USER.USER.token}`,
      "Content-Type": "application/json",
    },
  };

  axios(config)
    .then(function (response) {
      // console.log("OPP approved");
    })
    .catch(function (error) {
      // console.log("OPP not approved", error);
    });
};

export const rejectOPP = (id: string) => (dispatch: OPP_DISPATCH) => {
  var config = {
    method: "patch",
    url: `${process.env.REACT_APP_BASE_API}/opportunities/rejecteopportunity/${id}`,
    headers: {
      Authorization: `Bearer ${store.getState().USER.USER.token}`,
      "Content-Type": "application/json",
    },
  };

  axios(config)
    .then(function (response) {
      // console.log("OPP rejected");
    })
    .catch(function (error) {
      // console.log("OPP not rejected", error);
    });
};

export const getYourOppportunityCount = async (): Promise<number> => {
  let opportunitiesCount = 0;

  const url = `${process.env.REACT_APP_BASE_API}/opportunities/user/count`;
  const extoken: string = localStorage.getItem("GTI_data")?.split(" ")[0] ?? "";

  var config = {
    method: "get",
    url: url,
    headers: {
      Authorization: `Bearer ${extoken}`,
      "Content-Type": "application/json",
    },
  };

  await axios(config)
    .then(function (response) {
      opportunitiesCount = response.data.opportunitiesCount;
      return opportunitiesCount;
    })
    .catch(function (error) {});

  return opportunitiesCount;
};
