export enum PROFILE_TYPES {
  DISPLAYER = "DISPLAYER",
  SCOUTER = "SCOUTER",
  GENERAL_SUBSCRIBER = "GENERAL_SUBSCRIBER",
}

export enum VerificationStatus {
  PENDING = "PENDING",
  ACCEPTED = "ACCEPTED",
  REJECTED = "REJECTED",
}
