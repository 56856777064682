import React, { Dispatch, useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { Editor } from "@tinymce/tinymce-react";
import { useDispatch, useSelector } from "react-redux";
import { Form, Formik } from "formik";
import { useDetectClickOutside } from "react-detect-click-outside";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { Helmet } from "react-helmet";

import globe from "../../assests/home/globe.png";

import {
  CONTENT_TYPE,
  CONTENT_TYPE_DOC,
  FILE_PATH,
  FILE_TYPE,
  LIMIT,
  oppotunityItem,
  presignedData,
  sectorItem,
  SKIP,
  subsectorItem,
  title,
  metaData,
} from "../constants";
import { getSector } from "../../store/actioncreators/sectoractions";
import { getSubSector } from "../../store/actioncreators/sub-sectoractions";
import {
  failToast,
  successToast,
} from "../../store/actioncreators/toastactions";
import YourOpportunityList from "./YourOpportunityList";
import { createOppotunity } from "../../store/actioncreators/opportunityactions";
import { oppSchema } from "../validations/oppValidations";
import { notify } from "../../utils";
import { PROFILE_TYPES } from "../../shared/enum";
import { RequestMethods } from "../../shared/RequestMethods";
import SuccessModal from "./SuccessModal";
import "./style.css";

interface MyFormValues {
  name: string;
  tech_require: string;
  description: string;
  sectorId: string;
  subSectorId: string;
}

type files = {
  image: Boolean;
  document: Boolean;
  imageFile: File[];
  documentFiles: File[];
};

const OppModal = ({
  changeModalState,
  handleSuccessModal,
}: {
  changeModalState: () => void;
  handleSuccessModal: (isOpen: boolean, state: string, message: string) => void;
}) => {
  const sectorlist: SECTOR = useSelector((state: STATE) => state.SECTOR.SECTOR);
  const subsectorlist: SUB_SECTOR = useSelector(
    (state: STATE) => state.SUB_SECTOR.SUB_SECTOR
  );

  const initialValues: MyFormValues = {
    name: "",
    tech_require: "",
    description: "",
    sectorId: sectorlist.SECTOR_LIST[0]._id,
    subSectorId: subsectorlist.SUB_SECTOR_LIST[0]._id,
  };

  const dispatch: Dispatch<any> = useDispatch();
  const navigate = useNavigate();
  const user: USER = useSelector((state: STATE) => state.USER.USER);
  useEffect(() => {
    if (user.id && user.userType === PROFILE_TYPES.GENERAL_SUBSCRIBER) {
      notify("Unauthorized", "error");
      navigate("/");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const [files, setFiles] = useState<files>({
    image: false,
    document: false,
    imageFile: [],
    documentFiles: [],
  });

  const handleImage = function (e: React.ChangeEvent<HTMLInputElement>) {
    const fileList = e.target.files || [];
    if (!fileList) return;

    const images = [];
    const totalFiles = fileList?.length || 0;
    for (let i = 0; i < totalFiles; i++) {
      images.push(fileList[i]);
    }

    setFiles({ ...files, imageFile: images, image: false });
  };

  const handleDocuments = function (e: React.ChangeEvent<HTMLInputElement>) {
    const fileList = e.target.files || [];
    if (!fileList) return;

    const documentFiles = [];
    const totalFiles = fileList?.length || 0;
    for (let i = 0; i < totalFiles; i++) {
      documentFiles.push(fileList[i]);
    }

    setFiles({ ...files, documentFiles, document: false });
  };

  const getPresigned = async (content: presignedData) => {
    const data = JSON.stringify(content);
    let result: string = "";
    const config = {
      method: "post",
      url: `${process.env.REACT_APP_BASE_API}/users/getPresignedUrl`,
      headers: {
        "Content-Type": "application/json",
      },
      data,
    };

    await axios(config)
      .then(function (response) {
        result = response.data;
      })
      .catch(function (error) {
        result = "error";
        dispatch(failToast());
      });

    return result;
  };

  const postLogo = async (signed: string, imageFile: File) => {
    var config = {
      method: RequestMethods.PUT,
      url: signed,
      headers: {
        "Content-Type": CONTENT_TYPE,
        "Access-Control-Allow-Origin": true,
      },
      data: imageFile,
    };

    await axios(config)
      .then(function (response) {
        dispatch(successToast());
      })
      .catch(function (error) {});
  };

  const postDocument = async (signed: string, file: File) => {
    var config = {
      method: RequestMethods.PUT,
      url: signed,
      headers: {
        "Content-Type": CONTENT_TYPE_DOC,
        "Access-Control-Allow-Origin": true,
      },
      data: file,
    };

    await axios(config)
      .then(function (response) {
        dispatch(successToast());
      })
      .catch(function (error) {});
  };

  const handleCreate = async (values: MyFormValues) => {
    try {
      handleSuccessModal(true, "LOADING", "");
      if (!files.imageFile) {
        return setFiles({ ...files, image: true });
      }
      setFiles({ ...files, document: false, image: false });

      let opportunityImages: string[] = [];
      let documents: string[] = [];

      for (const file of files.imageFile) {
        const signedLogoData: presignedData = {
          fileName: file.name ?? values.name,
          filePath: FILE_PATH.PRODUCTS_IMAGE,
          fileType: FILE_TYPE.PNG,
        };
        let imageUrl = await getPresigned(signedLogoData);
        await postLogo(imageUrl, file);
        opportunityImages.push(imageUrl.split("?")[0]);
      }

      for (const file of files.documentFiles) {
        const signedDocumentData: presignedData = {
          fileName: file.name || values.name,
          filePath: FILE_PATH.COMPANY_DOCS,
          fileType: FILE_TYPE.PDF,
        };
        let documentUrl = await getPresigned(signedDocumentData);
        await postDocument(documentUrl, file);
        documents.push(documentUrl.split("?")[0]);
      }

      const data: oppotunityItem = {
        technologyPartnerRequirement: values.tech_require,
        description: values.description,
        documents,
        images: opportunityImages,
        sectorId: values.sectorId,
        subSectorId: values.subSectorId,
        name: values.name,
      };

      dispatch(createOppotunity(data));
      handleSuccessModal(
        true,
        "SUCCESS",
        "Opportunity has been created successfully, it will be reviewed and approved by Admin."
      );
      changeModalState();
    } catch (error) {
      handleSuccessModal(
        true,
        "ERROR",
        "There was an error while creating the product"
      );
      notify("Failed to create product!", "error");
    }
  };

  useEffect(() => {
    window.document.body.style.overflow = "hidden";
    return () => {
      window.document.body.style.overflow = "auto";
    };
  }, []);

  const content = (
    <div className="z-10 pb-[200px] pt-4 fixed w-full h-screen bg-slate-700 bg-opacity-70 top-0 left-0 flex justify-center overflow-auto">
      <div className="product-modal-main relative">
        <div className="flex">
          <h4 className="text-lg font-roboto">Create</h4>
          <button
            onClick={() => {
              changeModalState();
            }}
            className="absolute right-0 top-0 font-bold hover:text-red-500 duration-300 border border-slate-100 px-3 py-1 rounded"
          >
            X
          </button>
        </div>
        <Formik
          initialValues={initialValues}
          validationSchema={oppSchema}
          onSubmit={(values) => handleCreate(values)}
        >
          {({ handleChange, setFieldValue, handleSubmit, errors, values }) => (
            <>
              <Form className="flex flex-col w-full space-y-4 justify-center items-center">
                <div className="flex flex-col w-full space-x-2 relative">
                  <div className="flex flex-col w-full space-x-2 relative">
                    <div className="relative mb-4 ">
                      <input
                        type="text"
                        name="name"
                        id="floating_name"
                        onChange={(e) => setFieldValue("name", e.target.value)}
                        className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                        placeholder=" "
                      />
                      <label
                        htmlFor="floating_name"
                        className="absolute text-sm text-gray-500  duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white  px-2 peer-focus:px-2 peer-focus:text-blue-600  peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1"
                      >
                        Name
                      </label>
                    </div>
                  </div>
                  <div className="relative">
                    <textarea
                      onChange={(e) =>
                        setFieldValue("tech_require", e.target.value)
                      }
                      id="floating_outlined"
                      className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                      placeholder=" "
                    />
                    <label
                      htmlFor="floating_outlined"
                      className="absolute text-sm text-gray-500  duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white  px-2 peer-focus:px-2 peer-focus:text-blue-600  peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1"
                    >
                      Technology Requirement
                    </label>
                  </div>
                  {errors.tech_require && (
                    <p
                      id="filled_error_help"
                      className="mt-2 text-xs text-red-600 dark:text-red-400"
                    >
                      {errors.tech_require}
                    </p>
                  )}
                </div>
                <div className="flex flex-col w-full space-x-2 relative">
                  <div className="relative">
                    <Editor
                      apiKey={process.env.REACT_APP_TINYMCE_API_KEY}
                      init={{
                        height: 200,
                        menubar: false,
                        plugins: [
                          "advlist autolink lists link image charmap print preview anchor",
                          "searchreplace visualblocks code fullscreen",
                          "insertdatetime media table paste code help wordcount",
                        ],
                        toolbar:
                          "undo redo | formatselect | bold italic backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | help",
                        placeholder:
                          "Write your opportunity description here...",
                      }}
                      initialValue=""
                      onEditorChange={(e) => setFieldValue("description", e)}
                    />
                    <label
                      htmlFor="floating_outlined"
                      className="absolute text-sm text-gray-500  duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white  px-2 peer-focus:px-2 peer-focus:text-blue-600  peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1"
                    >
                      Opportunity Description
                    </label>
                  </div>
                  {errors.description && (
                    <p
                      id="filled_error_help"
                      className="mt-2 text-xs text-red-600 dark:text-red-400"
                    >
                      {errors.description}
                    </p>
                  )}
                </div>

                <div className="flex flex-col w-full">
                  <div className="flex flex-row w-full space-x-5 items-center">
                    <h3 className="font-robot text-gray-800 text-sm whitespace-nowrap  ">
                      Sector Type:
                    </h3>
                    <select
                      onChange={(e) =>
                        setFieldValue("sectorId", e.target.value)
                      }
                      defaultValue={values.sectorId}
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
                    >
                      {sectorlist.SECTOR_LIST.map((item: sectorItem, id) => {
                        return <option value={item._id}>{item.name}</option>;
                      })}
                    </select>
                  </div>
                  {errors.sectorId && (
                    <p
                      id="filled_error_help"
                      className="mt-2 text-xs text-red-600 dark:text-red-400"
                    >
                      {errors.sectorId}
                    </p>
                  )}
                </div>
                <div className="flex flex-col w-full">
                  <div className="flex flex-row w-full space-x-5 items-center">
                    <h3 className="font-robot text-gray-800 text-sm whitespace-nowrap  ">
                      Sub Sector Type:
                    </h3>
                    <select
                      onChange={(e) =>
                        setFieldValue("subsecId", e.target.value)
                      }
                      defaultValue={values.subSectorId}
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
                    >
                      {subsectorlist.SUB_SECTOR_LIST.map(
                        (item: subsectorItem, id) => {
                          return <option value={item._id}>{item.name}</option>;
                        }
                      )}
                    </select>
                  </div>
                  {errors.subSectorId && (
                    <p
                      id="filled_error_help"
                      className="mt-2 text-xs text-red-600 dark:text-red-400"
                    >
                      {errors.subSectorId}
                    </p>
                  )}
                </div>

                <div className="flex flex-col w-full">
                  <label
                    className="block mb-2 text-sm font-medium text-gray-900"
                    htmlFor="logo"
                  >
                    Upload Opportunity Image
                    <span className="text-red-500 text-xs">(.png only)</span>
                  </label>
                  <input
                    onChange={handleImage}
                    accept=".png"
                    type="file"
                    id="logo"
                    aria-label="company-logo"
                    className="modal-input"
                    placeholder="Click to upload Company's Logo"
                    multiple
                    max={3}
                  />
                  <p
                    id="filled_error_help"
                    className={
                      "mt-2 text-xs text-red-600 dark:text-red-400 " +
                      (!files.image ? "hidden" : "")
                    }
                  >
                    {"Please upload opportunity Image"}
                  </p>
                </div>
                <div className="flex flex-col w-full">
                  <label
                    className="block mb-2 text-sm font-medium text-gray-900"
                    htmlFor="documents"
                  >
                    Upload Opportunity Documents
                    <span className="text-red-500 text-xs">(.pdf only)</span>
                  </label>
                  <input
                    onChange={handleDocuments}
                    accept=".pdf"
                    type="file"
                    id="documents"
                    aria-label="company-documents"
                    className="modal-input"
                    placeholder="Click to upload Document"
                    multiple
                    max={3}
                  />
                  <p
                    id="filled_error_help"
                    className={
                      "mt-2 text-xs text-red-600 dark:text-red-400 " +
                      (!files.document ? "hidden" : "")
                    }
                  >
                    {"Please upload opportunity documents"}
                  </p>
                </div>
                <button
                  type="submit"
                  onClick={() => handleSubmit}
                  className="button active"
                >
                  Create
                </button>
              </Form>
            </>
          )}
        </Formik>
      </div>
    </div>
  );
  return ReactDOM.createPortal(content, document.body);
};

const YourOpportunites = () => {
  let [successModal, setSuccessModal] = useState<boolean>(false);
  const [state, setState] = useState("LOADING");
  const [message, setMessage] = useState("");

  const opp: OPP = useSelector((state: STATE) => state.OPP.OPP);
  const dispatch: Dispatch<any> = useDispatch();
  const [oppModal, setModel] = useState(false);

  const [page, setPage] = useState({
    skip: SKIP,
    limit: LIMIT,
  });

  const [type, setType] = useState({
    drop: false,
    selected: "Approved",
    id: 0,
    total: opp.TOTAL_APPROVED,
  });

  const ref1 = useDetectClickOutside({
    onTriggered: () => setType({ ...type, drop: false }),
  });

  const [sector, setSector] = useState({
    drop: false,
    selected: "",
    id: "",
  });
  const [subSector, setSubSector] = useState({
    drop: false,
    selected: "",
    id: "",
  });

  const fetchData = (value: number) => {
    let final = +page.skip + value < 0 ? +page.skip : +page.skip + value;
    setPage({ skip: final.toString(), limit: page.limit });
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    dispatch(getSector());
    dispatch(getSubSector());
    setSector({
      ...sector,
      selected: "All Sectors",
      id: "",
    });
    setSubSector({
      ...subSector,
      selected: "All Sub-Sectors",
      id: "",
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const changeModalState = async () => {
    setModel(!oppModal);
  };

  const handleSuccessModal = (
    isOpen: boolean,
    state: string,
    message: string
  ) => {
    setSuccessModal(isOpen);
    setState(state);
    setMessage(message);
  };

  const handleType = (index: number) => {
    if (index === 0) {
      setType({
        ...type,
        selected: "Approved",
        id: 0,
        total: opp.TOTAL_APPROVED,
        drop: false,
      });
    } else if (index === 1) {
      setType({
        ...type,
        selected: "Pending",
        id: 1,
        total: opp.TOTAL_PENDING,
        drop: false,
      });
    } else {
      setType({
        ...type,
        selected: "Rejected",
        id: 2,
        total: opp.TOTAL_REJECTED,
        drop: false,
      });
    }
  };

  return (
    <div className="flex flex-col relative py-10 items-center w-full">
      <Helmet>
        <title>{title.YOUR_OPPORTUNITIES}</title>
        <meta
          name="description"
          key="description"
          content={metaData.YOUR_OPPORTUNITIES}
        />
        <meta name="title" key="title" content="Your Opportunities" />
        <meta property="og:title" content="Your Opportunities" />
        <meta property="og:description" content={metaData.YOUR_OPPORTUNITIES} />
        <meta property="og:image" content={globe} />
        <meta
          property="og:url"
          content={`${process.env.REACT_APP_BASE_URL}/your-opportunities`}
        />
        <meta property="og:type" content="website" />
        <meta name="twitter:title" content="Your Opportunities" />
        <meta
          name="twitter:description"
          content={metaData.YOUR_OPPORTUNITIES}
        />
        <meta name="twitter:image" content={globe} />
        <meta name="twitter:card" content="Your Opportunities" />
      </Helmet>
      <div className="flex flex-row items-center">
        <h1 className="text-2xl font-roboto ">My Opportunity</h1>
      </div>
      <div className="flex w-full justify-between items-center  mx-10 py-5">
        <div
          ref={ref1}
          className="flex flex-row justify-end duration-200 z-10 px-5"
        >
          <button
            id="dropdownDefault"
            data-dropdown-toggle="dropdown"
            className="w-fit text-white border-2 bg-GTI-BLUE-default focus:outline-none font-medium font-roboto rounded-lg m-1 text-sm px-4 py-2.5  text-center inline-flex items-center border-slate-300 justify-center flex-shrink"
            type="button"
            onClick={() => {
              setType({ ...type, drop: !type.drop });
            }}
          >
            {type.selected}
            <svg
              className="ml-2 w-4 h-4"
              aria-hidden="true"
              fill="grey"
              stroke="grey"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M19 9l-7 7-7-7"
              ></path>
            </svg>
          </button>
          <div
            id="dropdown"
            className={"relative z-10 rounded " + (!type.drop ? "hidden" : "")}
            data-popper-placement="bottom"
          >
            <ul
              className="absolute flex flex-col  -left-28  top-14 text-sm font-roboto bg-slate-100"
              aria-labelledby="dropdownDefault"
            >
              <li
                className="block z-10 py-2 px-4 rounded  text-GTI-BLUE-default  whitespace-nowrap hover:text-slate-500 "
                onClick={() => {
                  handleType(0);
                }}
              >
                Approved
              </li>
              <li
                className="block z-10 py-2 px-4 rounded  text-GTI-BLUE-default  whitespace-nowrap hover:text-slate-500 "
                onClick={() => {
                  handleType(1);
                }}
              >
                Pending
              </li>
              <li
                className="block z-10 py-2 px-4 rounded  text-GTI-BLUE-default  whitespace-nowrap hover:text-slate-500 "
                onClick={() => {
                  handleType(2);
                }}
              >
                Rejected
              </li>
            </ul>
          </div>
        </div>
        <div className="flex  h-fit mx-10">
          <button
            type="button"
            className="button active"
            onClick={() => {
              setModel(true);
            }}
          >
            + Create New
          </button>
        </div>
      </div>
      <YourOpportunityList
        type={type.id}
        skip={page.skip}
        limit={page.limit}
        secId={sector.id}
        subsecId={subSector.id}
      />
      {oppModal && (
        <OppModal
          changeModalState={changeModalState}
          handleSuccessModal={handleSuccessModal}
        />
      )}
      <div className="flex mb-5 py-5">
        <button
          disabled={page.skip === "0"}
          onClick={() => {
            fetchData(-1);
          }}
          className="inline-flex items-center py-2 px-4 text-sm font-medium text-GTI-BLUE-default disabled:text-gray-500 bg-white  rounded-lg border border-gray-300 hover:bg-gray-100 hover:text-gray-700 "
        >
          <svg
            aria-hidden="true"
            className="mr-2 w-5 h-5"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              d="M7.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l2.293 2.293a1 1 0 010 1.414z"
              clipRule="evenodd"
            ></path>
          </svg>
          Previous
        </button>
        <button
          disabled
          className="inline-flex items-center mx-2 py-2 px-4 text-sm font-medium text-gray-500 bg-white rounded-lg border border-gray-300 hover:bg-gray-100 hover:text-gray-700 "
        >
          {+page.skip + 1}
        </button>
        <button
          disabled={(+page.skip + 1) * +page.limit >= type.total}
          onClick={() => {
            fetchData(1);
          }}
          className="inline-flex items-center py-2 px-4 text-sm font-medium text-GTI-BLUE-default disabled:text-gray-500 bg-white rounded-lg border border-gray-300 hover:bg-gray-100 hover:text-gray-700 "
        >
          Next
          <svg
            aria-hidden="true"
            className="ml-2 w-5 h-5"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z"
              clipRule="evenodd"
            ></path>
          </svg>
        </button>
      </div>
      {successModal && (
        <SuccessModal
          state={state}
          message={message}
          show={successModal}
          toggle={handleSuccessModal}
        />
      )}
    </div>
  );
};
export default YourOpportunites;
