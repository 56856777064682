import React, { Dispatch, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import { Helmet } from "react-helmet";

import { store } from "../../store";
import {
  registerEvent,
  registerEventPaymentLink,
} from "../../store/actioncreators/eventactionss";
import eventsDefault from "../../assests/images/events-default.jpg";
import gbi_home_logo from "../../assests/home/logo_home.svg";
import { eventItemsFetched } from "../constants";
import { Company } from "../../shared/constants";

const Event = ({ handleLoginModal }: { handleLoginModal: () => void }) => {
  const dispatch: Dispatch<any> = useDispatch();
  const { id } = useParams();
  const navigate = useNavigate();
  const state = {
    id: id ?? "",
  };

  let [event, setEvent] = useState<eventItemsFetched>({
    _id: "",
    topic: "",
    description: "",
    shortDescription: "",
    sectorId: "",
    subSectorId: "",
    eventType: "",
    imageUrl: "",
    startDate: "",
    endDate: "",
    externalLink: "",
    youtubeLink: "",
    webinarKey: "",
    webinarOrganizerKey: "",
    organizedBy: "",
    webinarRegistrationLink: "",
    createdAt: "",
    startTime: "",
    endTime: "",
    meetingLink: "",
    price: 0,
    videoUrl: "",
    __v: -1,
  });

  let [registered, setRegister] = useState(false);
  const user: USER = useSelector((state: STATE) => state.USER.USER);

  const loadEvent = (id: string) => {
    var config = {
      method: "PUT",
      url: `${process.env.REACT_APP_BASE_API}/events/${id}`,
      headers: {
        Authorization: `Bearer ${store.getState().USER.USER.token}`,
        "Content-Type": "application/json",
      },
    };

    axios(config)
      .then(function (response) {
        setEvent(response.data);
      })
      .catch(function (error) {});
  };

  const getRegistration = () => {
    const token = localStorage.getItem("GTI_data")?.split(" ")[0] ?? "";

    let config = {
      method: "GET",
      url: `${process.env.REACT_APP_BASE_API}/events/isUserRegistered/${state.id}`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    };

    axios(config)
      .then(function (response) {
        setRegister(response.data);
      })
      .catch(function (error) {});
  };

  useEffect(() => {
    loadEvent(state.id);
    getRegistration();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [registered]);

  const DOS = new Date(event?.startDate);
  const DOE = new Date(event?.endDate);

  const paymentHandler = async (
    amount: number,
    currency: string,
    order_id: string,
    promotionId: string
  ) => {
    let options = {
      key: process.env.REACT_APP_RAZORPAY_KEY_ID,
      amount,
      currency,
      name: Company.NAME,
      description: Company.DESCRIPTION,
      image: gbi_home_logo,
      order_id,
      callback_url: `${process.env.REACT_APP_BASE_API}/payments/validate`,
      prefill: {
        name: user?.user?.name,
        email: user?.user?.email,
      },
      notes: {
        promotionId,
      },
      theme: {
        color: "#3399cc",
      },
    };

    let rzp1 = new (window as any).Razorpay(options);

    rzp1.on("payment.failed", function (response: any) {
      alert("Payment Failed. Please Retry again.");
      navigate("/featured/failed");
    });

    rzp1.open();
  };

  const handleRegister = async () => {
    if (user.admin !== -1) {
      if (!registered) {
        if (event?.webinarRegistrationLink) {
          window.open(event?.webinarRegistrationLink, "_blank");
        } else if (event?.price) {
          const { order_id, currency, amount, promotionId } =
            await registerEventPaymentLink(event?._id);

          paymentHandler(amount, currency, order_id, promotionId);
        } else {
          dispatch(registerEvent(event?._id));
          setRegister(true);
        }
      } else if (event?.meetingLink) {
        window.open(event?.meetingLink, "_blank");
      } else if (event?.youtubeLink) {
        window.open(event?.youtubeLink, "_blank");
      } else if (event?.price) {
        navigate(`/eventvideo/${event._id}`);
      }
      return;
    }
    handleLoginModal();
  };

  const fetchButtonText = () => {
    let text = "";
    if (user.admin !== -1) {
      if (registered) {
        if (event?.youtubeLink) {
          text = "View Recording";
        } else if (event?.meetingLink) {
          text = "Join Meeting";
        } else if (event?.price) {
          text = "View Content";
        } else {
          text = "Already registered";
        }
      } else if (event?.price) {
        text = `Pay $${event?.price} to Register`;
      } else {
        text = "Register";
      }
    } else {
      text = "Please sign in to Register";
    }

    return text;
  };

  return (
    <React.Fragment>
      <div className="event-main">
        <Helmet>
          <title>{event.topic?.replace(/(<([^>]+)>)/gi, "")}</title>
          <meta
            name="description"
            key="description"
            content={event.description?.replace(/(<([^>]+)>)/gi, "")}
          />
          <meta name="title" key="title" content={event.topic} />
          <meta property="og:title" content={event.topic} />
          <meta property="og:description" content={event.description} />
          <meta
            property="og:image"
            content={event.imageUrl ? event.imageUrl : eventsDefault}
          />
          <meta
            property="og:url"
            content={`${process.env.REACT_APP_BASE_URL}/events/${event._id}`}
          />
          <meta property="og:type" content="website" />
          <meta name="twitter:title" content={event.topic} />
          <meta name="twitter:description" content={event.description} />
          <meta
            name="twitter:image"
            content={event.imageUrl ? event.imageUrl : eventsDefault}
          />
          <meta name="twitter:card" content={event.topic} />
        </Helmet>
        <img
          src={event.imageUrl ? event.imageUrl : eventsDefault}
          className={event.topic}
          alt={event.topic?.replace(/(<([^>]+)>)/gi, "")}
          loading="lazy"
        />
        <div className="event-parent-details">
          <div className="event-details">
            <div className="event-group flex justify-between items-center">
              <h4 className="text-gray-500 font-roboto">
                {DOS.toLocaleString("default", {
                  month: "long",
                  day: "2-digit",
                  year: "numeric",
                })}
              </h4>
            </div>
            <div className="event-group">
              <h1
                className="text-lg font-bold lg:text-4xl font-roboto"
                dangerouslySetInnerHTML={{
                  __html: event.topic?.replace(/(<([^>]+)>)/gi, ""),
                }}
              ></h1>
              <br />
              <div className="max-w-full overflow-hidden text-sm lg:text-lg font-roboto whitespace-nowrap">
                <p className="whitespace-normal break-words">
                  <span className="font-bold">Organised by:</span>&nbsp;
                  {event.organizedBy}
                </p>
              </div>
            </div>
            <div
              className="event-group text-sm lg:text-lg"
              style={{ lineHeight: "30px" }}
            >
              <h4 className="text-black font-roboto">
                <b className="text-black">Event Start Date and Time: </b>
                {DOS.toLocaleString("default", {
                  month: "long",
                  day: "2-digit",
                  year: "numeric",
                })}{" "}
                | {event.startTime}
              </h4>
              <h4 className="text-black font-roboto">
                <b className="text-black">Event End Date and Time: </b>
                {DOE.toString() !== "Invalid Date"
                  ? DOE.toLocaleString("default", {
                      month: "long",
                      day: "2-digit",
                      year: "numeric",
                    })
                  : DOS.toLocaleString("default", {
                      month: "long",
                      day: "2-digit",
                      year: "numeric",
                    })}
                &nbsp; | {event.endTime}
              </h4>
              <p className="text-xm font-bold font-roboto whitespace-nowrap">
                Event Mode: &nbsp;
                <span className="font-normal">{event.eventType}</span>
              </p>
            </div>
            <div className="event-group text-xs lg:text-lg">
              <p dangerouslySetInnerHTML={{ __html: event.description }}></p>
            </div>
            <button
              type="button"
              className={"button active"}
              onClick={() => {
                handleRegister();
              }}
            >
              {fetchButtonText()}
            </button>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Event;
