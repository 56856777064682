import React, { useRef, useState } from "react";
import ReactFlagsSelect from "react-flags-select";
import { Dispatch } from "redux";
import { useSelector, useDispatch } from "react-redux";
import { ImCross } from "react-icons/im";
import axios from "axios";
import { redirect } from "react-router-dom";
import { useFormik } from "formik";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import { MdArrowRightAlt } from "react-icons/md";
import { Helmet } from "react-helmet";

import companyDetails from "../../assests/images/signup/company-details.png";
import displayer from "../../assests/images/signup/displayer.png";
import generalSubscriber from "../../assests/images/signup/general-subscriber.png";
import scouter from "../../assests/images/signup/scouter.png";
import upload_logo_baner from "../../assests/banners/upload_logo.png";
import * as ROUTE from "../Constants/routes";
import {
  companySignupSchema,
  companySignupSchema2,
  personalSignupSchema,
} from "../validations/signupValidations";
import {
  CONTENT_TYPE,
  CONTENT_TYPE_DOC,
  DOCUMENT_TYPE,
  FILE_PATH,
  FILE_TYPE,
  metaData,
  presignedData,
  title,
} from "../constants";
import { Spinner } from "../utils/loader";
import {
  spinnerLoaderStart,
  spinnerLoaderStop,
} from "../../store/actioncreators/loaderactions";
import { formatUserType, isSuccess, notify } from "../../utils";
import { createUser } from "../../api/user";
import {
  countryList,
  displayerMessage,
  generalSubscriberMessage,
  scouterMessage,
} from "../../shared/constants";
import { PROFILE_TYPES } from "../../shared/enum";
import {
  Document,
  FormValues,
  FormValues2,
  PersonalFormValues,
} from "../../shared/interface";
import SuccessModal from "./SuccessModal";
import ErrorModal from "./ErrorModal";
import { RequestMethods } from "../../shared/RequestMethods";
import globe from "../../assests/home/globe.png";
import "./style.css";

const Signup = ({ handleLoginModal }: { handleLoginModal: () => void }) => {
  const [signUpImage, setSignUpImage] = useState(generalSubscriber);
  const [signUpMessage, setSignUpMessage] = useState(generalSubscriberMessage);
  const Error_modal = document.getElementById("error-modal");
  let [successModal, setSuccessModal] = useState<boolean>(false);
  let [errorModal, setErrorModal] = useState({
    show: false,
    message: "",
  });
  let handleSuccessModal = () => {
    setSuccessModal(!successModal);
  };
  let handleErrorModal = (msg: string) => {
    setErrorModal({ show: !errorModal.show, message: msg });
  };
  const [showPassword, setShowPassword] = useState(false);
  const dispatch: Dispatch<any> = useDispatch();
  const loader: LOADER = useSelector((state: STATE) => state.LOADER.LOADER);
  let [country, setCountry] = useState("IN");

  //company
  let [companyTab, toggleCompany] = useState(2);
  let [uploadLogo, setUploadLogoM] = useState(false);
  const [comp_logo, setCompLogo] = useState<FormValues["logo"]>(null);
  const [comp_documents, setCompDocs] = useState<FormValues["documents"]>([]);
  const [profileType, setProfileType] = useState(PROFILE_TYPES.DISPLAYER);
  const [comp_documents_start, setCompDocsStart] = useState<
    FormValues["s_documents"]
  >({ document: null, documentType: DOCUMENT_TYPE.OPEN });

  const formik = useFormik<FormValues>({
    initialValues: {
      name: "",
      description: "",
      address: "",
      website: "",
      country: "",
      turnover: "",
      logo: null,
      documents: [],
      s_documents: { document: null, documentType: DOCUMENT_TYPE.OPEN },
    },
    validationSchema: companySignupSchema,
    onSubmit: (values, formikHelpers) => {
      handleCompanySubmit(values);
    },
  });

  const personal_formik = useFormik<PersonalFormValues>({
    initialValues: {
      name: "",
      email: "",
      password: "",
      country: "",
      phone: "",
      ref: "",
    },
    validationSchema: personalSignupSchema,
    onSubmit: (values, formikHelpers) => {
      const isCompany = profileType !== PROFILE_TYPES.GENERAL_SUBSCRIBER;
      !isCompany ? handleGeneralProfile(values) : handleCompanySubmitWithId();
    },
  });
  const formik2 = useFormik<FormValues2>({
    initialValues: {
      comp_id: "",
    },
    validationSchema: companySignupSchema2,
    onSubmit: (values, formikHelpers) => {
      handleCompanySubmitWithId();
    },
  });

  const handleGeneralProfile = async (values: PersonalFormValues) => {
    dispatch(spinnerLoaderStart());
    const data = {
      userType: profileType,
      fullName: values.name,
      email: values.email,
      countryCode: country,
      phoneNumber: values.phone.toString(),
      referenceCode: values.ref,
      password: values.password,
    };
    const res = await createUser(data);
    dispatch(spinnerLoaderStop());
    if (isSuccess(res)) {
      handleSuccessModal();
      notify("Registered successfully!", "success");
    } else {
      notify("Registration failed!", "error");
    }
  };

  let toggle_error = () => {
    if (Error_modal?.hasAttribute("hidden")) {
      Error_modal?.classList.remove("hidden");
      Error_modal?.classList.add("container");
    } else {
      Error_modal?.classList.remove("container");
      Error_modal?.classList.add("hidden");
    }
  };

  const handleCompLogoChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCompLogo(e.target.files?.[0] || null);
    formik.setFieldValue("logo", e.target.files![0]);
  };

  const handleRemoveCompLogo = () => {
    setCompLogo(null);
    formik.setFieldValue("logo", null);
  };

  let handleCompanySubmitWithId = () => {
    dispatch(spinnerLoaderStart());
    let data = JSON.stringify({
      userType: profileType,
      fullName: personal_formik.values.name,
      email: personal_formik.values.email,
      countryCode: country,
      phoneNumber: personal_formik.values.phone.toString(),
      referenceCode: personal_formik.values.ref,
      password: personal_formik.values.password,
      companyId: formik2.values.comp_id,
      companyDetails: {
        name: null,
        logo: null,
        description: null,
        address: null,
        website: null,
        country: null,
        companyTurnover: null,
      },
      companyDocuments: [],
    });
    let config = {
      method: RequestMethods.POST,
      url: `${process.env.REACT_APP_BASE_API}/users`,
      headers: {
        "Content-Type": "application/json",
      },
      data,
    };

    axios(config)
      .then(function (response) {
        if (response.data.status === 200 || response.data.status === 201) {
          redirect(ROUTE.HOME);
          handleSuccessModal();
          dispatch(spinnerLoaderStop());
        } else {
          handleErrorModal(response.data.message);
          dispatch(spinnerLoaderStop());
        }
      })
      .catch(function (error) {
        handleErrorModal("Error in Submitting , Please try again");
        dispatch(spinnerLoaderStop());
      });
  };

  const getPresigned = async (content: presignedData) => {
    const data = JSON.stringify(content);
    let result: string = "";
    const config = {
      method: RequestMethods.POST,
      url: `${process.env.REACT_APP_BASE_API}/users/getPresignedUrl`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    await axios(config)
      .then(function (response) {
        result = response.data;
      })
      .catch(function (error) {
        result = "error";
      });

    return result;
  };

  const postFile = async (signed: string) => {
    var config = {
      method: RequestMethods.PUT,
      url: signed,
      headers: {
        "Content-Type": CONTENT_TYPE,
        "Access-Control-Allow-Origin": true,
      },
      data: comp_logo,
    };

    await axios(config)
      .then(function (response) {})
      .catch(function (error) {
        toggle_error();
      });
  };

  const postDocument = async (signed: string, file: File | null) => {
    var config = {
      method: RequestMethods.PUT,
      url: signed,
      headers: {
        "Content-Type": CONTENT_TYPE_DOC,
        "Access-Control-Allow-Origin": true,
      },
      data: file,
    };
    if (file) {
      await axios(config)
        .then(function (response) {})
        .catch(function (error) {
          toggle_error();
        });
    }
  };

  let handleCompanySubmit = async (values: FormValues) => {
    dispatch(spinnerLoaderStart());
    let signedLogoURL: string = "";
    let signedDocumentURLWhole: string = "";

    const signedData: presignedData = {
      fileName: values?.logo?.name || values.name,
      filePath: FILE_PATH.COMPANY_LOGO,
      fileType: FILE_TYPE.PNG,
    };
    signedLogoURL = await getPresigned(signedData);
    postFile(signedLogoURL);
    comp_documents.push(comp_documents_start);

    const temp_docs: any[] = [];
    if (comp_documents) {
      Array.from(comp_documents).forEach(async (document: Document, i) => {
        let signedDocumentData: presignedData = {
          fileName: document.document?.name || values.name,
          filePath: FILE_PATH.COMPANY_DOCS,
          fileType: FILE_TYPE.PDF,
        };
        let tempurl = (await getPresigned(signedDocumentData)) + " ";
        temp_docs.push({
          document: tempurl,
          documentType: document.documentType,
        });
        signedDocumentURLWhole = tempurl.split("?")[0] + " ";
        postDocument(tempurl, document.document);
      });
    }

    let data = JSON.stringify({
      userType: profileType,
      fullName: personal_formik.values.name,
      email: personal_formik.values.email,
      countryCode: country,
      phoneNumber: personal_formik.values.phone.toString(),
      referenceCode: personal_formik.values.ref,
      password: personal_formik.values.password,
      companyId: null,
      companyDetails: {
        name: values.name,
        logo: signedLogoURL.split("?")[0],
        description: values.description,
        address: values.address,
        website: values.website,
        country: values.country,
        companyTurnover: values.turnover,
      },
      companyDocuments: temp_docs,
    });

    let config = {
      method: RequestMethods.POST,
      url: `${process.env.REACT_APP_BASE_API}/users`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        if (response.data.status === 200 || response.data.status === 201) {
          dispatch(spinnerLoaderStop());
          handleSuccessModal();
          formik.resetForm();
          formik2.resetForm();
        } else {
          handleErrorModal(response.data.message);
          dispatch(spinnerLoaderStop());
        }
      })
      .catch(function (error) {
        handleErrorModal("Error in Submitting , Please try again");
        dispatch(spinnerLoaderStop());
      });
  };

  let handleLogoUpload = (event: any) => {
    setUploadLogoM(!uploadLogo);
    setCompLogo(event.target.files[0]);
  };

  const companyRef = useRef<HTMLDivElement>(null);

  const handleType = (key: any) => {
    setProfileType(key);
    if (key === PROFILE_TYPES.DISPLAYER) {
      setSignUpImage(displayer);
      setSignUpMessage(displayerMessage);
    } else if (key === PROFILE_TYPES.SCOUTER) {
      setSignUpImage(scouter);
      setSignUpMessage(scouterMessage);
    } else if (key === PROFILE_TYPES.GENERAL_SUBSCRIBER) {
      setSignUpImage(generalSubscriber);
      setSignUpMessage(generalSubscriberMessage);
    }
  };

  return (
    <div className="flex flex-col justify-center w-full items-center -z-10">
      <Helmet>
        <title>{title.SIGN_UP}</title>
        <meta name="description" key="description" content={metaData.SIGN_UP} />
        <meta name="title" key="title" content={title.SIGN_UP} />
        <meta property="og:title" content={title.SIGN_UP} />
        <meta property="og:description" content={metaData.SIGN_UP} />
        <meta property="og:image" content={globe} />
        <meta
          property="og:url"
          content={`${process.env.REACT_APP_BASE_URL}/signup`}
        />
        <meta property="og:type" content="website" />
        <meta name="twitter:title" content={title?.SIGN_UP} />
        <meta name="twitter:description" content={metaData?.SIGN_UP} />
        <meta name="twitter:image" content={globe} />
        <meta name="twitter:card" content={title?.SIGN_UP} />
      </Helmet>
      {
        <>
          <>
            <form
              onSubmit={personal_formik.handleSubmit}
              className="flex w-full"
            >
              <div className="flex flex-col items-center w-full duration-200">
                <div className="flex w-10/12 md:mt-12 items-center">
                  <div className="hidden md:flex flex-col items-center justify-center w-6/12">
                    <p className="text-base p-2 font-roboto border rounded-xl w-4/5">
                      {signUpMessage}
                    </p>
                    <img
                      src={signUpImage}
                      className="w-3/5 h-3/5"
                      alt="Sign Up"
                    />
                  </div>
                  <div className="md:w-6/12 flex flex-col md:mb-10 w-full">
                    <div>
                      <p className="text-white bg-GTI-BLUE-default p-2 text-sm md:text-lg rounded-lg">
                        Are you a tech displayer / scouter or general
                        subscriber? Choose One
                      </p>
                      <div className="flex flex-col md:flex-row justify-between my-2">
                        {Object.values(PROFILE_TYPES).map((value: string) => (
                          <button
                            className={`w-full rounded-lg px-4 py-2 mx-1 text-sm md:text-lg mb-2 ${
                              value === profileType
                                ? "bg-GTI-BLUE-default text-white"
                                : "bg-white border-2 text-black"
                            }`}
                            type="button"
                            onClick={() => handleType(value)}
                          >
                            {formatUserType(value)}
                          </button>
                        ))}
                      </div>
                    </div>
                    <div className="flex flex-row items-stretch justify-evenly m-3 mb-5">
                      <div className="relative w-96">
                        <input
                          type="text"
                          id="name"
                          name="name"
                          value={personal_formik.values.name}
                          onChange={personal_formik.handleChange}
                          className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                          placeholder=" "
                        />
                        <label
                          htmlFor="name"
                          id="fname_label"
                          className={
                            "absolute text-sm text-gray-500  duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white  px-2 peer-focus:px-2  peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1 " +
                            (personal_formik.errors.name
                              ? "text-red-500 peer-focus:text-red-500"
                              : "text-gray-500 peer-focus:text-blue-600")
                          }
                        >
                          {personal_formik.errors.name
                            ? personal_formik.errors.name
                            : "*Full Name"}
                        </label>
                      </div>
                    </div>
                    <div className="flex flex-row items-stretch justify-evenly m-3 mb-5">
                      <div className="relative w-96">
                        <input
                          type="text"
                          id="email"
                          name="email"
                          value={personal_formik.values.email}
                          onChange={personal_formik.handleChange}
                          className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                          placeholder=" "
                        />
                        <label
                          htmlFor="email"
                          id="email"
                          className={
                            "absolute text-sm text-gray-500  duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white  px-2 peer-focus:px-2  peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1 " +
                            (personal_formik.errors.email
                              ? "text-red-500 peer-focus:text-red-500"
                              : "text-slate-500 peer-focus:text-blue-600")
                          }
                        >
                          {!personal_formik.errors.email
                            ? "*Email id"
                            : personal_formik.errors.email}
                        </label>
                      </div>
                    </div>
                    <div className="flex flex-row items-stretch justify-evenly m-3 mb-5">
                      <div className="relative w-96">
                        <input
                          type={showPassword ? "text" : "password"}
                          id="password"
                          name="password"
                          value={personal_formik.values.password}
                          onChange={personal_formik.handleChange}
                          className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                          placeholder=" "
                        />
                        <button
                          type="button"
                          onClick={() => setShowPassword((prev) => !prev)}
                          className="border-none absolute top-[4px] right-[10px] h-[40px]"
                        >
                          {showPassword ? (
                            <AiFillEyeInvisible size={24} />
                          ) : (
                            <AiFillEye size={24} />
                          )}
                        </button>
                        <label
                          htmlFor="password"
                          id="label_pass"
                          className={
                            "absolute text-sm text-gray-500  duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white  px-2 peer-focus:px-2  peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1 " +
                            (personal_formik.errors.password
                              ? "text-red-500 peer-focus:text-red-500"
                              : "text-slate-500 peer-focus:text-blue-600")
                          }
                        >
                          {!personal_formik.errors.password
                            ? " *Set Password"
                            : personal_formik.errors.password}
                        </label>
                      </div>
                    </div>
                    <div className="flex flex-row items-stretch justify-evenly m-3 mb-5">
                      <span className="inline-flex w-96">
                        <ReactFlagsSelect
                          selected={country}
                          onSelect={(code) => {
                            personal_formik.setFieldValue("country", code);
                            setCountry(code);
                          }}
                          id="country"
                          fullWidth={false}
                          selectedSize={18}
                          showSelectedLabel={false}
                          showSecondarySelectedLabel={false}
                          showSecondaryOptionLabel={false}
                          searchable
                          className="block  px-1 py-1 text-sm text-gray-900 bg-transparent rounded-lg border-1 appearance-none  focus:outline-none"
                        />
                        <div className="relative w-full">
                          <input
                            type="number"
                            id="phone"
                            maxLength={10}
                            name="phone"
                            value={personal_formik.values.phone}
                            onChange={personal_formik.handleChange}
                            className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                            placeholder=" "
                          />
                          <label
                            id="phone_label"
                            htmlFor="phone"
                            className={
                              "absolute text-sm text-gray-500  duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white  px-2 peer-focus:px-2  peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1 " +
                              (personal_formik.errors.phone
                                ? "text-red-500 peer-focus:text-red-500"
                                : "text-slate-500 peer-focus:text-blue-600")
                            }
                          >
                            {!personal_formik.errors.phone
                              ? " *Phone Number"
                              : personal_formik.errors.phone}
                          </label>
                        </div>
                      </span>
                    </div>
                    <div className="flex flex-row items-stretch justify-evenly m-3 mb-5">
                      <div className="relative w-96">
                        <input
                          type="text"
                          id="ref"
                          name="ref"
                          value={personal_formik.values.ref}
                          onChange={personal_formik.handleChange}
                          className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                          placeholder=" "
                        />
                        <label
                          htmlFor="ref"
                          id="ref"
                          className="absolute text-sm text-gray-500 peer-focus:text-blue-600  duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white  px-2 peer-focus:px-2  peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1"
                        >
                          Reference Code
                        </label>
                      </div>
                    </div>
                    <button
                      className={`${
                        profileType === PROFILE_TYPES.GENERAL_SUBSCRIBER
                          ? "block"
                          : "hidden"
                      } mt-6 flex gap-2 items-center justify-center w-full rounded bg-white hover:bg-GTI-BLUE-default px-5 py-3 border border-GTI-BLUE-default font-medium text-GTI-BLUE-default hover:text-white shadow-md shadow-indigo-500/20`}
                    >
                      Submit
                      <MdArrowRightAlt />
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </>

          <div
            ref={companyRef}
            className="flex flex-col items-center h-full md:w-10/12 w-full"
          >
            {profileType !== PROFILE_TYPES.GENERAL_SUBSCRIBER && (
              <div
                className={
                  "flex flex-row-reverse relative w-3/4 md:w-10/12 justify-center md:space-x-4 duration-200 " +
                  (companyTab === 2 ? "md:mt-10" : "mt-32")
                }
              >
                <div className="hidden md:flex flex-col items-center justify-start w-6/12 mb-10 ">
                  <p className="text-4xl my-5 font-roboto">Company Details</p>
                  <div className="flex flex-col w-5/6 h-2/3 items-center">
                    <img
                      src={companyDetails}
                      alt="Company Details"
                      className="w-full h-4/5"
                    />
                  </div>
                </div>
                <div className="flex flex-col h-full md:w-6/12 items-center">
                  <div className="flex justify-center w-full items-center">
                    <>
                      <form
                        onSubmit={formik.handleSubmit}
                        className="flex flex-col mb-24 md:w-4/5"
                      >
                        <div className="flex flex-col space-y-3">
                          <div className="flex flex-col w-full">
                            <div className="relative my-1">
                              <input
                                value={formik.values.name}
                                onChange={formik.handleChange}
                                id="companyName"
                                type="text"
                                name="name"
                                className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                placeholder=" "
                              />
                              <label
                                htmlFor="companyName"
                                id="label_compName"
                                className="absolute text-sm text-gray-500 peer-focus:text-blue-600  duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white  px-2 peer-focus:px-2  peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1"
                              >
                                Company Name
                              </label>
                            </div>
                            {formik.errors.name && (
                              <p className="text-red-500 text-xs p-2">
                                {formik.errors.name}
                              </p>
                            )}
                          </div>
                          <div className="flex flex-col w-full">
                            <div className="relative my-1">
                              <textarea
                                id="description"
                                value={formik.values.description}
                                onChange={formik.handleChange}
                                className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                placeholder=" "
                              />
                              <label
                                htmlFor="description"
                                id="label_compName"
                                className="absolute text-sm text-gray-500 peer-focus:text-blue-600  duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white  px-2 peer-focus:px-2  peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1"
                              >
                                Company Description
                              </label>
                            </div>
                            {formik.errors.description && (
                              <p className="text-red-500 text-xs p-2">
                                {formik.errors.description}
                              </p>
                            )}
                          </div>
                          <div className="flex flex-col w-full">
                            <div className="relative my-1">
                              <textarea
                                id="address"
                                value={formik.values.address}
                                onChange={formik.handleChange}
                                className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                placeholder=" "
                              />
                              <label
                                htmlFor="address"
                                id="label_address"
                                className="absolute text-sm text-gray-500 peer-focus:text-blue-600  duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white  px-2 peer-focus:px-2  peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1"
                              >
                                Company Address
                              </label>
                            </div>
                            {formik.errors.address && (
                              <p className="text-red-500 text-xs p-2">
                                {formik.errors.address}
                              </p>
                            )}
                          </div>
                          <div className="flex flex-col w-full">
                            <div className="relative my-1">
                              <input
                                id="website"
                                type="text"
                                value={formik.values.website}
                                onChange={formik.handleChange}
                                className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                placeholder=" "
                              />
                              <label
                                htmlFor="website"
                                id="label_compWebsite"
                                className="absolute text-sm text-gray-500 peer-focus:text-blue-600  duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white  px-2 peer-focus:px-2  peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1"
                              >
                                Company's Website
                              </label>
                            </div>
                            {formik.errors.website && (
                              <p className="text-red-500 text-xs p-2">
                                {formik.errors.website}
                              </p>
                            )}
                          </div>
                          <div className="flex flex-col w-full">
                            <select
                              id="country"
                              value={formik.values.country}
                              onChange={formik.handleChange}
                              className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                            >
                              <option value="" disabled>
                                Country
                              </option>
                              {countryList.map((country, index) => (
                                <option key={index} value={country.symbol}>
                                  {country.name}
                                </option>
                              ))}
                            </select>
                            {formik.errors.country && (
                              <p className="text-red-500 text-xs p-2">
                                {formik.errors.country}
                              </p>
                            )}
                          </div>
                          <div className="flex flex-col w-full">
                            <select
                              id="turnover"
                              value={formik.values.turnover}
                              onChange={formik.handleChange}
                              className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                            >
                              <option value="" disabled>
                                Turnover/Fund (in USD) (optional)
                              </option>
                              <option value="1">1M</option>
                              <option value="2">1-10M</option>
                              <option value="3">10-20M</option>
                              <option value="4">20-50M</option>
                              <option value="5">50-100M</option>
                              <option value="6">100-250M</option>
                            </select>
                            {formik.errors.turnover && (
                              <p className="text-red-500 text-xs p-2">
                                {formik.errors.turnover}
                              </p>
                            )}
                          </div>
                          <div className="flex flex-col w-full">
                            {comp_logo ? (
                              <div className="flex flex-row justify-between items-center border border-gray-300 p-3 rounded-md">
                                <p className="font-roboto text-sm text-gray-700 px-4 whitespace-nowrap">
                                  Uploaded Logo
                                </p>
                                <button
                                  onClick={handleRemoveCompLogo}
                                  type="button"
                                  className="text-white w-1/2 bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex justify-between items-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                                >
                                  {comp_logo.name.length > 12
                                    ? comp_logo.name.substring(0, 12)
                                    : comp_logo.name}
                                  <ImCross className="w-5 h-5 p-1" />
                                </button>
                              </div>
                            ) : (
                              <div className="flex items-center justify-center w-full">
                                <label
                                  htmlFor="logo"
                                  className="flex flex-col items-center justify-center w-full  border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 "
                                >
                                  <div className="flex flex-col items-center justify-center pt-5 pb-6">
                                    <svg
                                      aria-hidden="true"
                                      className="w-10 h-10 mb-3 text-gray-400"
                                      fill="none"
                                      stroke="currentColor"
                                      viewBox="0 0 24 24"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                                      ></path>
                                    </svg>
                                    <p className="mb-2 text-sm text-gray-500">
                                      <span className="font-semibold">
                                        Click to upload Logo
                                      </span>{" "}
                                      or drag and drop
                                    </p>
                                    <p className="text-xs text-gray-500 ">
                                      Company Logo{" "}
                                      <span className="text-blue-500">
                                        (.png)
                                      </span>
                                    </p>
                                  </div>
                                  <input
                                    id="logo"
                                    type="file"
                                    className="hidden"
                                    accept="image/png"
                                    onChange={handleCompLogoChange}
                                  />
                                </label>
                              </div>
                            )}
                            {formik.errors.logo && (
                              <p className="text-red-500 text-xs">
                                {formik.errors.logo}
                              </p>
                            )}
                          </div>
                          <button
                            type="submit"
                            className="flex w-full justify-center text-GTI-BLUE-default hover:text-white border border-GTI-BLUE-default  hover:bg-GTI-BLUE-default focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2"
                          >
                            SUBMIT
                            {loader.SPINNER && <Spinner />}
                          </button>
                        </div>
                      </form>
                    </>
                  </div>
                </div>
              </div>
            )}
            {uploadLogo ? (
              <div className="z-10 flex absolute duration-200 ease-in-out w-full h-full justify-center bg-slate-700 bg-opacity-30">
                <input
                  id="upload_logo_file"
                  onChange={(e) => {
                    handleLogoUpload(e);
                  }}
                  type="file"
                  accept="image/*"
                  style={{ display: "none" }}
                />
                <label
                  htmlFor="upload_logo_file"
                  className="flex duration-150 -translate-y-20 justify-center items-center w-2/3 "
                >
                  <img
                    className="w-2/3 h-1/2"
                    src={upload_logo_baner}
                    alt="Upload Logo"
                  />
                </label>
              </div>
            ) : null}
            <div
              id="error-modal"
              tabIndex={-1}
              className="hidden"
              aria-hidden="true"
            >
              <div className="main -translate-y-1/2 relative  w-full max-w-md h-full md:h-auto">
                <div className="relative bg-white rounded-lg">
                  <button
                    type="button"
                    onClick={() => {
                      toggle_error();
                    }}
                    className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white"
                    data-modal-toggle="error-modal"
                  >
                    <svg
                      aria-hidden="true"
                      className="w-5 h-5"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                    <span className="sr-only">Close modal</span>
                  </button>
                  <div className="p-6 text-center">
                    <svg
                      aria-hidden="true"
                      className="mx-auto mb-4 w-14 h-14 text-gray-400 dark:text-gray-200"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                      ></path>
                    </svg>
                    <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
                      Some error occurred while submiting your details...
                    </h3>
                    <button
                      data-modal-toggle="error-modal"
                      onClick={() => {
                        toggle_error();
                      }}
                      type="button"
                      className="text-white bg-blue-600 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-red-800 font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center mr-2"
                    >
                      OK
                    </button>
                  </div>
                </div>
              </div>
            </div>

            {successModal && (
              <SuccessModal show={successModal} toggle={handleSuccessModal} />
            )}
            {errorModal.show && (
              <ErrorModal modal={errorModal} toggle={handleErrorModal} />
            )}
          </div>
        </>
      }
    </div>
  );
};

export default Signup;
