import { BsFillPatchCheckFill } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import ReactDOM from "react-dom";

const SuccessModal = ({
  show,
  toggle,
}: {
  show: boolean;
  toggle: () => void;
}) => {
  let navigate = useNavigate();

  const content = (
    <div
      id="error-modal"
      tabIndex={-1}
      className={show ? "container fixed top-0 left-0" : "hidden"}
      aria-hidden="true"
    >
      <div className="bg-white justify-center md:inline-flex max-w-md py-4 px-8 rounded-lg md:mx-auto mx-4 bg-opacity-100 -translate-y-1/2 relative  h-full md:h-auto">
        <div className="relative bg-white rounded-lg space-y-2">
          <div className="flex flex-col justify-evenly items-center p-6 text-center">
            <BsFillPatchCheckFill fill="green" className="w-16 m-6 h-16" />
            <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
              An email has been sent on your mail address, please verify it
              within 24 hours.
            </h3>
            <button
              data-modal-toggle="error-modal"
              onClick={() => {
                toggle();
                navigate("/");
              }}
              type="button"
              className="text-white w-fit bg-GTI-BLUE-default hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center mr-2"
            >
              Home
            </button>
          </div>
        </div>
      </div>
    </div>
  );
  return ReactDOM.createPortal(content, document.body);
};

export default SuccessModal;
