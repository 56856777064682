import { BsFillPatchExclamationFill } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { ErrorModalInterface } from "../../shared/interface";
import ReactDOM from "react-dom";

const ErrorModal = ({
  modal,
  toggle,
}: {
  modal: ErrorModalInterface;
  toggle: (msg: string) => void;
}) => {
  let navigate = useNavigate();
  const content = (
    <div
      id="error-modal"
      tabIndex={-1}
      className={modal.show ? "container fixed top-0 left-0" : "hidden"}
      aria-hidden="true"
    >
      <div className="bg-white justify-center md:inline-flex max-w-md py-4 px-8 rounded-lg md:mx-auto mx-4 bg-opacity-100 -translate-y-1/2 relative  h-full md:h-auto">
        <div className="relative bg-white rounded-lg space-y-2">
          <button
            type="button"
            onClick={() => {
              toggle("");
              navigate("/signup");
            }}
            className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white"
            data-modal-toggle="error-modal"
          >
            <svg
              aria-hidden="true"
              className="w-5 h-5"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clipRule="evenodd"
              ></path>
            </svg>
            <span className="sr-only">Close modal</span>
          </button>
          <div className="flex flex-col justify-evenly items-center p-6 text-center">
            <BsFillPatchExclamationFill fill="red" className="w-16 m-6 h-16" />
            <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
              {modal.message}
            </h3>
            <button
              data-modal-toggle="error-modal"
              onClick={() => {
                toggle("");
                navigate("/signup");
              }}
              type="button"
              className="text-white w-fit bg-GTI-BLUE-default hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center mr-2"
            >
              OK
            </button>
          </div>
        </div>
      </div>
    </div>
  );
  return ReactDOM.createPortal(content, document.body);
};

export default ErrorModal;
