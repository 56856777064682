import axios from "axios";
import { productItem, productUpdateItem } from "../../Components/constants";
import { store } from "../../store";
import { GET_PRODUCTS } from "../actiontypes/actionTypes";
import { spinnerLoaderStart, spinnerLoaderStop } from "./loaderactions";
import { notify } from "../../utils";

export const getProducts =
  (
    skip: string,
    limit: string,
    sectorId?: string,
    subSectorId?: string,
    search?: string
  ) =>
  (dispatch: any) => {
    sectorId = sectorId === undefined ? "" : sectorId;
    subSectorId = subSectorId === undefined ? "" : subSectorId;
    dispatch(spinnerLoaderStart());
    let configUnsigned = {
      method: "get",
      url: `${process.env.REACT_APP_BASE_API}/products?skip=${skip}&limit=${limit}&sectorId=${sectorId}&subSectorId=${subSectorId}&search=${search}`,
    };
    let configSigned = {
      method: "get",
      url: `${process.env.REACT_APP_BASE_API}/products/loggedinusers?skip=${skip}&limit=${limit}&sectorId=${sectorId}&subSectorId=${subSectorId}&search=${search}`,
      headers: {
        Authorization: `Bearer ${store.getState().USER.USER.token}`,
        "Content-Type": "application/json",
      },
    };

    axios(
      store.getState().USER.USER.admin !== -1 ? configSigned : configUnsigned
    )
      .then(function (response) {
        const list: PRODUCTS = {
          TOTAL: response.data.productsCount,
          PRODUCTS_LIST: response.data,
          YOUR_PRODUCT_LIST:
            store.getState().PRODUCTS.PRODUCTS.YOUR_PRODUCT_LIST,
          COMPANY_PRODUCT_LIST:
            store.getState().PRODUCTS.PRODUCTS.COMPANY_PRODUCT_LIST,
          COMPANY_PENDING_PRODUCT_LIST:
            store.getState().PRODUCTS.PRODUCTS.COMPANY_PENDING_PRODUCT_LIST,
          COMPANY_APPROVED_PRODUCT_LIST:
            store.getState().PRODUCTS.PRODUCTS.COMPANY_APPROVED_PRODUCT_LIST,
          COMPANY_UNAPPROVED_PRODUCT_LIST:
            store.getState().PRODUCTS.PRODUCTS.COMPANY_UNAPPROVED_PRODUCT_LIST,
          COMPANY_REJECTED_PRODUCT_LIST:
            store.getState().PRODUCTS.PRODUCTS.COMPANY_REJECTED_PRODUCT_LIST,
          TOTAL_PENDING: store.getState().PRODUCTS.PRODUCTS.TOTAL_PENDING,
          TOTAL_APPROVED: store.getState().PRODUCTS.PRODUCTS.TOTAL_APPROVED,
          TOTAL_UNAPPROVED: store.getState().PRODUCTS.PRODUCTS.TOTAL_UNAPPROVED,
          TOTAL_REJECTED: store.getState().PRODUCTS.PRODUCTS.TOTAL_REJECTED,
        };
        const action: GET_PRODUCTS_ACTION = {
          type: GET_PRODUCTS,
          PRODUCTS: list,
        };
        dispatch(action);
        dispatch(spinnerLoaderStop());
      })
      .catch(function (error) {
        dispatch(spinnerLoaderStop());
      });
  };
export const getYourProducts =
  (skip: string, limit: string, option: string) => (dispatch: any) => {
    const new_skip = (+skip * +limit).toString();
    dispatch(spinnerLoaderStart());

    let url = `${process.env.REACT_APP_BASE_API}/products/user?productStatus=${option}`;
    const extoken: string =
      localStorage.getItem("GTI_data")?.split(" ")[0] ?? "";

    var config = {
      method: "get",
      url: url,
      headers: {
        Authorization: `Bearer ${extoken}`,
        "Content-Type": "application/json",
      },
    };

    axios(config)
      .then(function (response) {
        const list: PRODUCTS = {
          TOTAL: +response.data.productsCount,
          PRODUCTS_LIST: store.getState().PRODUCTS.PRODUCTS.PRODUCTS_LIST,
          YOUR_PRODUCT_LIST: response.data,
          COMPANY_PRODUCT_LIST:
            store.getState().PRODUCTS.PRODUCTS.COMPANY_PRODUCT_LIST,
          COMPANY_PENDING_PRODUCT_LIST:
            store.getState().PRODUCTS.PRODUCTS.COMPANY_PENDING_PRODUCT_LIST,
          COMPANY_APPROVED_PRODUCT_LIST:
            store.getState().PRODUCTS.PRODUCTS.COMPANY_APPROVED_PRODUCT_LIST,
          COMPANY_UNAPPROVED_PRODUCT_LIST:
            store.getState().PRODUCTS.PRODUCTS.COMPANY_UNAPPROVED_PRODUCT_LIST,
          COMPANY_REJECTED_PRODUCT_LIST:
            store.getState().PRODUCTS.PRODUCTS.COMPANY_REJECTED_PRODUCT_LIST,
          TOTAL_PENDING: store.getState().PRODUCTS.PRODUCTS.TOTAL_PENDING,
          TOTAL_APPROVED: store.getState().PRODUCTS.PRODUCTS.TOTAL_APPROVED,
          TOTAL_UNAPPROVED: store.getState().PRODUCTS.PRODUCTS.TOTAL_UNAPPROVED,
          TOTAL_REJECTED: store.getState().PRODUCTS.PRODUCTS.TOTAL_REJECTED,
        };
        const action: GET_PRODUCTS_ACTION = {
          type: GET_PRODUCTS,
          PRODUCTS: list,
        };
        dispatch(action);
        dispatch(spinnerLoaderStop());
      })
      .catch(function (error) {
        dispatch(spinnerLoaderStop());
      });
  };
export const createProduct =
  (item: productItem) => (dispatch: PRODUCT_DISPATCH) => {
    let data: productItem = {
      name: item.name,
      description: item.description,
      documents: item.documents,
      images: item.images,
      sectorId: item.sectorId,
      subSectorId: item.subSectorId,
      developmentStage: item.developmentStage,
      iprStatus: item.iprStatus,
    };
    if (item.videos) {
      data.videos = item.videos;
    }

    let config = {
      method: "post",
      url: `${process.env.REACT_APP_BASE_API}/products`,
      headers: {
        Authorization: `Bearer ${store.getState().USER.USER.token}`,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        // console.log("Product created");
        notify("Product created!", "success");
        // window.location.reload();
      })
      .catch(function (error) {
        // console.log("Product not created", error);
        notify("Failed to create product!", "error");
      });
  };
export const updateProduct =
  (item: productUpdateItem) => (dispatch: PRODUCT_DISPATCH) => {
    var data: productUpdateItem = {
      productId: item.productId,
      name: item.name,
      description: item.description,
      document: item.document,
      image: item.image,
      sectorId: item.sectorId,
      subSectorId: item.subSectorId,
      developmentStage: item.developmentStage,
      iprStatus: item.iprStatus,
    };
    var config = {
      method: "patch",
      url: `${process.env.REACT_APP_BASE_API}/products`,
      headers: {
        Authorization: `Bearer ${store.getState().USER.USER.token}`,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        // console.log("Product updated");
        notify("Updated successfully!", "success");
        window.location.reload();
      })
      .catch(function (error) {
        // console.log("Product not updated", error);
        notify("Failed to update!", "success");
      });
  };

//company admin
export const getCompanyPendingProducts =
  (skip: string, limit: string) => (dispatch: PRODUCT_DISPATCH) => {
    const new_skip = (+skip * +limit).toString();
    var config = {
      method: "get",
      url: `${process.env.REACT_APP_BASE_API}/products/companypendingproducts`,
      headers: {
        Authorization: `Bearer ${store.getState().USER.USER.token}`,
        "Content-Type": "application/json",
      },
    };

    axios(config)
      .then(function (response) {
        const list: PRODUCTS = {
          TOTAL: store.getState().PRODUCTS.PRODUCTS.TOTAL_PENDING,
          PRODUCTS_LIST: store.getState().PRODUCTS.PRODUCTS.PRODUCTS_LIST,
          YOUR_PRODUCT_LIST:
            store.getState().PRODUCTS.PRODUCTS.YOUR_PRODUCT_LIST,
          COMPANY_PRODUCT_LIST:
            store.getState().PRODUCTS.PRODUCTS.COMPANY_PRODUCT_LIST,
          COMPANY_PENDING_PRODUCT_LIST: response.data[0].data,
          COMPANY_APPROVED_PRODUCT_LIST:
            store.getState().PRODUCTS.PRODUCTS.COMPANY_APPROVED_PRODUCT_LIST,
          COMPANY_UNAPPROVED_PRODUCT_LIST:
            store.getState().PRODUCTS.PRODUCTS.COMPANY_UNAPPROVED_PRODUCT_LIST,
          COMPANY_REJECTED_PRODUCT_LIST:
            store.getState().PRODUCTS.PRODUCTS.COMPANY_REJECTED_PRODUCT_LIST,
          TOTAL_PENDING: +response.data[0].metadata.total,
          TOTAL_APPROVED: store.getState().PRODUCTS.PRODUCTS.TOTAL_APPROVED,
          TOTAL_UNAPPROVED: store.getState().PRODUCTS.PRODUCTS.TOTAL_UNAPPROVED,
          TOTAL_REJECTED: store.getState().PRODUCTS.PRODUCTS.TOTAL_REJECTED,
        };
        const action: GET_PRODUCTS_ACTION = {
          type: GET_PRODUCTS,
          PRODUCTS: list,
        };
        return dispatch(action);
      })
      .catch(function (error) {});
  };

export const getCompanyUnapprovedProducts =
  (skip: string, limit: string) => (dispatch: PRODUCT_DISPATCH) => {
    const new_skip = (+skip * +limit).toString();
    var config = {
      method: "get",
      url: `${process.env.REACT_APP_BASE_API}/products/companyunapprovedproducts`,
      headers: {
        Authorization: `Bearer ${store.getState().USER.USER.token}`,
        "Content-Type": "application/json",
      },
    };

    axios(config)
      .then(function (response) {
        const list: PRODUCTS = {
          TOTAL: +response.data[0].metadata.total,
          PRODUCTS_LIST: store.getState().PRODUCTS.PRODUCTS.PRODUCTS_LIST,
          YOUR_PRODUCT_LIST:
            store.getState().PRODUCTS.PRODUCTS.YOUR_PRODUCT_LIST,
          COMPANY_PRODUCT_LIST:
            store.getState().PRODUCTS.PRODUCTS.COMPANY_PRODUCT_LIST,
          COMPANY_PENDING_PRODUCT_LIST:
            store.getState().PRODUCTS.PRODUCTS.COMPANY_PENDING_PRODUCT_LIST,
          COMPANY_APPROVED_PRODUCT_LIST:
            store.getState().PRODUCTS.PRODUCTS.COMPANY_APPROVED_PRODUCT_LIST,
          COMPANY_UNAPPROVED_PRODUCT_LIST: response.data[0].data,
          COMPANY_REJECTED_PRODUCT_LIST:
            store.getState().PRODUCTS.PRODUCTS.COMPANY_REJECTED_PRODUCT_LIST,
          TOTAL_PENDING: store.getState().PRODUCTS.PRODUCTS.TOTAL_PENDING,
          TOTAL_APPROVED: store.getState().PRODUCTS.PRODUCTS.TOTAL_APPROVED,
          TOTAL_UNAPPROVED: +response.data[0].metadata.total,
          TOTAL_REJECTED: store.getState().PRODUCTS.PRODUCTS.TOTAL_REJECTED,
        };
        const action: GET_PRODUCTS_ACTION = {
          type: GET_PRODUCTS,
          PRODUCTS: list,
        };
        return dispatch(action);
      })
      .catch(function (error) {});
  };

export const getCompanyApprovedProducts =
  (skip: string, limit: string) => (dispatch: PRODUCT_DISPATCH) => {
    const new_skip = (+skip * +limit).toString();
    var config = {
      method: "get",
      url: `${process.env.REACT_APP_BASE_API}/products/companyapprovedproducts`,
      headers: {
        Authorization: `Bearer ${store.getState().USER.USER.token}`,
        "Content-Type": "application/json",
      },
    };

    axios(config)
      .then(function (response) {
        const list: PRODUCTS = {
          TOTAL: +response.data[0].metadata.total,
          PRODUCTS_LIST: store.getState().PRODUCTS.PRODUCTS.PRODUCTS_LIST,
          YOUR_PRODUCT_LIST:
            store.getState().PRODUCTS.PRODUCTS.YOUR_PRODUCT_LIST,
          COMPANY_PRODUCT_LIST:
            store.getState().PRODUCTS.PRODUCTS.COMPANY_PRODUCT_LIST,
          COMPANY_PENDING_PRODUCT_LIST:
            store.getState().PRODUCTS.PRODUCTS.COMPANY_PENDING_PRODUCT_LIST,
          COMPANY_APPROVED_PRODUCT_LIST: response.data[0].data,
          COMPANY_UNAPPROVED_PRODUCT_LIST:
            store.getState().PRODUCTS.PRODUCTS.COMPANY_UNAPPROVED_PRODUCT_LIST,
          COMPANY_REJECTED_PRODUCT_LIST:
            store.getState().PRODUCTS.PRODUCTS.COMPANY_REJECTED_PRODUCT_LIST,
          TOTAL_PENDING: store.getState().PRODUCTS.PRODUCTS.TOTAL_APPROVED,
          TOTAL_APPROVED: +response.data[0].metadata.total,
          TOTAL_UNAPPROVED: store.getState().PRODUCTS.PRODUCTS.TOTAL_UNAPPROVED,
          TOTAL_REJECTED: store.getState().PRODUCTS.PRODUCTS.TOTAL_REJECTED,
        };
        const action: GET_PRODUCTS_ACTION = {
          type: GET_PRODUCTS,
          PRODUCTS: list,
        };
        return dispatch(action);
      })
      .catch(function (error) {});
  };
export const getCompanyRejectedProducts =
  (skip: string, limit: string) => (dispatch: PRODUCT_DISPATCH) => {
    const new_skip = (+skip * +limit).toString();
    var config = {
      method: "get",
      url: `${process.env.REACT_APP_BASE_API}/products/companyrejectedproducts`,
      headers: {
        Authorization: `Bearer ${store.getState().USER.USER.token}`,
        "Content-Type": "application/json",
      },
    };

    axios(config)
      .then(function (response) {
        const list: PRODUCTS = {
          TOTAL: +response.data[0].metadata.total,
          PRODUCTS_LIST: store.getState().PRODUCTS.PRODUCTS.PRODUCTS_LIST,
          YOUR_PRODUCT_LIST:
            store.getState().PRODUCTS.PRODUCTS.YOUR_PRODUCT_LIST,
          COMPANY_PRODUCT_LIST:
            store.getState().PRODUCTS.PRODUCTS.COMPANY_PRODUCT_LIST,
          COMPANY_PENDING_PRODUCT_LIST:
            store.getState().PRODUCTS.PRODUCTS.COMPANY_PENDING_PRODUCT_LIST,
          COMPANY_APPROVED_PRODUCT_LIST:
            store.getState().PRODUCTS.PRODUCTS.COMPANY_APPROVED_PRODUCT_LIST,
          COMPANY_UNAPPROVED_PRODUCT_LIST:
            store.getState().PRODUCTS.PRODUCTS.COMPANY_UNAPPROVED_PRODUCT_LIST,
          COMPANY_REJECTED_PRODUCT_LIST: response.data[0].data,
          TOTAL_PENDING: store.getState().PRODUCTS.PRODUCTS.TOTAL_PENDING,
          TOTAL_APPROVED: store.getState().PRODUCTS.PRODUCTS.TOTAL_APPROVED,
          TOTAL_UNAPPROVED: store.getState().PRODUCTS.PRODUCTS.TOTAL_UNAPPROVED,
          TOTAL_REJECTED: +response.data[0].metadata.total,
        };
        const action: GET_PRODUCTS_ACTION = {
          type: GET_PRODUCTS,
          PRODUCTS: list,
        };
        return dispatch(action);
      })
      .catch(function (error) {});
  };

export const approveProduct = (id: string) => (dispatch: PRODUCT_DISPATCH) => {
  var config = {
    method: "patch",
    url: `${process.env.REACT_APP_BASE_API}/products/approveproduct/${id}`,
    headers: {
      Authorization: `Bearer ${store.getState().USER.USER.token}`,
      "Content-Type": "application/json",
    },
  };

  axios(config)
    .then(function (response) {
      // console.log("Product approved");
    })
    .catch(function (error) {
      // console.log("Product not approved", error);
    });
};

export const rejectProduct = (id: string) => (dispatch: PRODUCT_DISPATCH) => {
  var config = {
    method: "patch",
    url: `${process.env.REACT_APP_BASE_API}/products/rejecteproduct/${id}`,
    headers: {
      Authorization: `Bearer ${store.getState().USER.USER.token}`,
      "Content-Type": "application/json",
    },
  };

  axios(config)
    .then(function (response) {
      // console.log("Product rejected");
    })
    .catch(function (error) {
      // console.log("Product not rejected", error);
    });
};

export const getYourProductCount = async (): Promise<number> => {
  let productsCount = 0;

  const url = `${process.env.REACT_APP_BASE_API}/products/user/count`;
  const extoken: string = localStorage.getItem("GTI_data")?.split(" ")[0] ?? "";

  var config = {
    method: "get",
    url: url,
    headers: {
      Authorization: `Bearer ${extoken}`,
      "Content-Type": "application/json",
    },
  };

  await axios(config)
    .then(function (response) {
      productsCount = response.data;
      return productsCount;
    })
    .catch(function (error) {});

  return productsCount;
};
